<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :model="infoForm" :rules="rules" :inline="true" ref="form">
            <el-form-item :label="$t('fence.shape')" label-width="100px" prop="shape">
              <el-select :placeholder="$t('fence.shape')" v-model="infoForm.shape">
                <el-option :value="1" :label="$t('fence.circle')"></el-option>
                <el-option :value="2" :label="$t('fence.polygon')"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('fence.type')" label-width="100px" prop="type">
              <el-select :placeholder="$t('fence.type')" v-model="infoForm.type">
                <el-option :value="1" :label="$t('fence.normal')"></el-option>
                <el-option :value="2" :label="$t('fence.flood')"></el-option>
                <el-option :value="3" :label="$t('fence.safety')"></el-option>
                <el-option :value="4" :label="$t('fence.construction')"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('fence.name')" label-width="100px" prop="name">
              <el-input v-model="infoForm.name"></el-input>
            </el-form-item>
            <el-form-item :label="$t('fence.details')" label-width="100px" prop="content">
              <el-input type="textarea" v-model="infoForm.content"></el-input>
            </el-form-item>
            <el-form-item :label="$t('fence.radius')" label-width="100px" prop="radius" v-if="infoForm.shape == 1">
              <el-input v-model="infoForm.radius"></el-input>
            </el-form-item>
            <el-form-item :label="$t('fence.alarm_type')" label-width="100px" prop="alarm_type">
              <el-select :placeholder="$t('fence.alarm_type')" v-model="infoForm.alarm_type">
                <el-option :value="1" :label="$t('fence.enter')"></el-option>
                <el-option :value="2" :label="$t('fence.leave')"></el-option>
                <el-option :value="3" :label="$t('fence.enter_leave')"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('fence.duration')" label-width="100px" prop="date">
              <el-date-picker
                  type="daterange"
                  v-model="infoForm.date"
              ></el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('fence.alarm_level')" label-width="100px" prop="level">
              <el-select :placeholder="$t('fence.alarm_level')" v-model="infoForm.level">
                <el-option :value="1" :label="$t('fence.red')"></el-option>
                <el-option :value="2" :label="$t('fence.orange')"></el-option>
                <el-option :value="3" :label="$t('fence.yellow')"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('fence.status')" label-width="100px" prop="status">
              <el-radio-group v-model="infoForm.status">
                <el-radio :label="1">{{ $t('fence.on') }}</el-radio>
                <el-radio :label="2">{{ $t('fence.off') }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
                class="addinfo"
                :label="$t('group.select_device')"
                label-width="100px"
                prop="imei"
                v-if="infoForm.source==1"
            >
              <el-input readonly v-model="infoForm.imei"/>
              <el-button @click="addfence" type="primary">{{ $t('device.add') }}</el-button>
              <el-button @click="delfence" type="primary">{{ $t('device.delete') }}</el-button>
            </el-form-item>
          </el-form>
          <el-dialog :title="dialogTit" center v-model="addDialog" width="70%">
            <div style="padding-bottom:60px;">
              <el-form :model="addform" :inline="true" ref="searchForm">
                <el-form-item label="IMEI" prop="imei_keyword">
                  <el-input :placeholder="$t('notice.enter_imei')" v-model="addform.imei_keyword"></el-input>
                </el-form-item>
                <el-form-item :label="$t('index.device_type')" prop="type">
                  <el-select clearable :placeholder="$t('index.device_type')" v-model="addform.type">
                    <el-option
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                        v-for="item in deviceType"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('device.device_status')" prop="online">
                  <el-select :placeholder="$t('device.device_status')" v-model="addform.online">
                    <el-option :label="$t('index.online')" value="1"></el-option>
                    <el-option :label="$t('index.offline')" value="0"></el-option>
                    <el-option :label="$t('index.stripping_outline')" value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('device.vesting')" prop="client_id">
                  <el-cascader
                      :options="clientData"
                      :placeholder="$t('device.vesting')"
                      v-model="addform.client_id"
                  ></el-cascader>
                </el-form-item>
                <el-form-item>
                  <el-button @click="addSearch" type="primary">{{ $t('device.inquire') }}</el-button>
                  <el-button @click="resetSearch">{{ $t('device.reset') }}</el-button>
                  <el-button @click="handleAddDevice" type="primary">{{ $t('group.save') }}</el-button>
                </el-form-item>
              </el-form>
              <el-table :data="deviceData" @select="handleSelect" @select-all="handleSelectAll" ref="deviceTable">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column :label="$t('index.device')" property="imei" width="150"></el-table-column>
                <el-table-column :label="$t('index.device_type')" property="type"></el-table-column>
                <el-table-column :label="$t('device.device_status')" property="online"></el-table-column>
                <el-table-column :label="$t('index.device_name')" property="name"></el-table-column>
                <el-table-column :label="$t('device.c')" property="create_at"></el-table-column>
              </el-table>
              <el-pagination
                  :page-size="addform.size"
                  :total="addCount"
                  @current-change="handleCurrentChange"
                  @size-change="handleSizeChange"
                  layout="total, prev, pager, next, jumper"
              ></el-pagination>
            </div>
          </el-dialog>
        </div>
      </template>
      <div style="text-align:right;margin-right:60px;margin-bottom:20px">
        <el-button @click="clearFence" :disabled="coordinate.length===0" type="danger" style="margin-right:30px">{{
            $t('fence.clear')
          }}
        </el-button>
        <el-button @click="operateFence" type="primary">{{ $t('group.save') }}</el-button>
        <el-button @click="goBack">{{ $t('device.cancel') }}</el-button>
      </div>
      <div id="container" style="height:500px" v-show="mapShow"></div>
      <div id="myPageTop" style="position: relative;top: -490px;">
        <label>{{ $t('device.input_keywords') }}：</label> <input id="tipinput"/>
      </div>
    </el-card>
  </div>
</template>
<script>
import device from "@/model/device";
import fence from "@/model/fence";
import AMapLoader from "@amap/amap-jsapi-loader";
import tool from "../../utils/tool";
import clients from "@/model/client";

/**
 * 绑定实现：
 * Step1.给table绑定多选框点击（select）事件
 * Step2.根据选中数据的imei进行判断，存在则删除imei，不存在则push进选中的数组里-为了做分页时也能数据选中
 * Step3.获取设备列表初始化表格之后判断imei通过toggleRowSelection设置是否选中
 *        PS：注意需要表格初始化之后设置，通过setTimeout做异步处理
 * Step4.新增和删除的时候将选中的设备列表赋给中间变量，为了做选中和提交的数据区分
 *
 * Remark selectDevice设备列表选中的数据
 *        addform.imei 设备列表的筛选条件
 */
export default {
  name: "groupAlarm",
  data() {
    return {
      infoForm: {
        imei: tool.isEmpty(this.$route.query.imei)
            ? []
            : [this.$route.query.imei],
        id: this.$route.query.id,
        shape: "",
        type: "",
        level: "",
        date: [],
        name: "",
        content: "",
        sid: "",
        gfid: "",
        key: "",
        alarm_type: "",
        radius: "",
        coordinate: [],
        source: this.$route.query.source,
        status: 1
      },
      amapConfig: {
        sid: "",
        key: ""
      },
      addform: {
        imei: "",
        imei_keyword: "",
        type: "",
        online: "",
        client_id: "",
        page: 1,
        size: 6
      },
      addCount: 0,
      addDialog: false,
      dialogTit: this.$t('group.add_device'),
      deviceType: [],
      deviceData: [],
      clientData: [],
      coordinate: [],
      selectDevice: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      // mapInterface: null,
      color: {
        1: "#d71345",
        2: "#f58220",
        3: "#dea43c"
      },
      rules: {
        shape: [{required: true, message: this.$t('fence.select_shape'), trigger: "blur"}],
        type: [{required: true, message: this.$t('fence.select_type'), trigger: "blur"}],
        name: [{required: true, message: this.$t('fence.select_name'), trigger: "blur"}],
        alarm_type: [
          {required: true, message: this.$t('fence.select_alarm'), trigger: "blur"}
        ],
        date: [{required: true, message: this.$t('fence.select_period'), trigger: "blur"}],
        level: [{required: true, message: this.$t('fence.select_level'), trigger: "blur"}],
        imei: [{required: true, message: this.$t('fence.select_device')}],
        content: [
          {required: true, message: this.$t('fence.select_detail'), trigger: "blur"}
        ]
      },
      longitude: '',
      latitude: '',
      is_online: 0,
      location: ''
    };
  },
  computed: {
    mapShow() {
      if (
          !tool.isEmpty(this.infoForm.shape) &&
          !tool.isEmpty(this.infoForm.level)
      ) {
        this.clear();
        return true;
      } else {
        return false;
      }
    }
  },
  async mounted() {
    if (!tool.isEmpty(this.$route.query.imei)) {
      await this.loadDevice()
    }
    await this.clientList();
    await this.deviceTypeArr();
    await this.amapInfo();
  },
  methods: {
    async loadDevice() {
      const ret = await device.info({imei: this.$route.query.imei})
      if (ret.code === 200) {
        if (!ret.data.longitude || !ret.data.latitude) {
          this.$message.warning(this.$t('detail.location_failed'))
          return false
        }
        this.longitude = ret.data.longitude
        this.latitude = ret.data.latitude
        this.is_online = ret.data.is_online
        this.location = ret.data.location
      }
    },
    async loadFenceInfo() {
      const ret = await fence.info({
        id: this.$route.query.id
      });
      if (ret.code === 200) {
        this.infoForm = ret.data;
        this.infoForm.date =
            ret.data.start_time != ""
                ? [ret.data.start_time, ret.data.end_time]
                : [];
        this.infoForm.imei = ret.data.imei_list;
        setTimeout(() => {
          this.infoForm.coordinate.forEach(v => {
            this.coordinate.push([v.longitude, v.latitude]);
          });
          if (this.infoForm.shape === 1) {
            this.mapMouse.close(true);
            this.circleAmap();
          } else {
            this.mapMouse.close(true);
            this.polygonAmap();
          }
        }, 1000);
      }
    },
    async clientList() {
      const ret = await clients.list();
      if (ret.code === 200) {
        this.clientData = this.handleClientTree(ret.data)
      }
    },
    handleClientTree(data) {
      if (data.length === 0) {
        return []
      }
      let options = []
      data.forEach(item => {
        options.push({
          value: item.id,
          label: item.name
        })
        if (item.children.length > 0) {
          options.push({
            value: item.id,
            label: item.name,
            children: this.handleClientTree(item.children)
          })
        }
      })
      return options
    },
    async deviceTypeArr() {
      const ret = await device.config();
      if (ret.code === 200) {
        this.deviceType = ret.data;
      }
    },
    async amapInfo() {
      this.mapInit();
    },
    async deviceList() {
      const ret = await device.group(this.addform);
      if (ret.code === 200) {
        this.deviceData = ret.data.list;
        this.addCount = ret.data.count;
        setTimeout(() => {
          this.deviceData.forEach(v => {
            this.$refs.deviceTable.toggleRowSelection(
                v,
                this.selectDevice.indexOf(v.imei) > -1
            );
          });
        });
      }
    },
    operateFence() {
      if (this.infoForm.coordinate.length === 0) {
        this.$message.warning(this.$t('fence.draw_fence'));
        return false;
      }
      this.$refs.form.validate(async valid => {
        if (valid) {
          const form = JSON.parse(JSON.stringify(this.infoForm));
          form.imei = form.imei.join(",");
          form.sid = this.amapConfig.sid;
          form.key = this.amapConfig.key;
          form.coordinate = JSON.stringify(form.coordinate);
          const ret = await fence.operate(form);
          if (ret.code === 200) {
            this.$message.success(this.$t('fence.success'));
            this.goBack();
          } else {
            this.$message.error(ret.message);
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.back();
    },
    addfence() {
      this.addDialog = true;
      this.dialogTit = this.$t('group.add_device');
      this.selectDevice = JSON.parse(JSON.stringify(this.infoForm.imei));
      this.addform.imei = "";
      this.addform.imei_keyword = "";
      this.deviceList();
    },
    delfence() {
      this.addDialog = true;
      this.dialogTit = this.$t('group.delete_device');
      this.selectDevice = JSON.parse(JSON.stringify(this.infoForm.imei));
      this.addform.imei_keyword = ''
      this.addform.imei =
          this.selectDevice.length == 0 ? [''] : this.selectDevice.join(",");
      this.deviceList();
    },
    handleAddDevice() {
      this.infoForm.imei = this.selectDevice;
      this.addDialog = false;
    },
    handleSelect(selection, row) {
      const index = this.selectDevice.indexOf(row.imei);
      if (index > -1) {
        this.selectDevice.splice(index, 1);
      } else {
        this.selectDevice.push(row.imei);
      }
    },
    handleSelectAll(selection) {
      if (selection.length === 0) {
        this.deviceData.forEach(v => {
          let index = this.selectDevice.indexOf(v.imei);
          if (index > -1) {
            this.selectDevice.splice(index, 1);
          }
        })
      } else {
        this.deviceData.forEach(v => {
          let index = this.selectDevice.indexOf(v.imei);
          if (index < 0) {
            this.selectDevice.push(v.imei);
          }
        })
      }
    },
    addSearch() {
      this.deviceList();
    },
    resetSearch() {
      this.$refs.searchForm.resetFields();
      this.deviceList();
    },
    handleSizeChange(val) {
      this.addform.size = val;
      this.deviceList();
    },
    handleCurrentChange(val) {
      this.addform.page = val;
      this.deviceList();
    },
    mapInit() {
      AMapLoader.load({
        key: this.$store.state.amap_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: this.$store.state.amap_version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] //插件列表
      })
          .then(AMap => {
            this.amap = AMap;
            let paramMap = {
              resizeEnable: true,
              zoom: 13,
              center: [116.3458203, 39.9107495]
            }
            if (this.longitude != '' && this.latitude != '') {
              paramMap.center = [this.longitude, this.latitude]
            }
            this.mapInterface = new AMap.Map("container", paramMap);
            //输入提示
            this.amap.plugin(['AMap.PlaceSearch', 'AMap.Autocomplete'], () => {
              const auto = new AMap.Autocomplete({
                input: "tipinput"
              })
              const placeSearch = new AMap.PlaceSearch({
                map: this.mapInterface
              })  //构造地点查询类
              auto.on("select", (e) => {
                placeSearch.setCity(e.poi.adcode);
                placeSearch.search(e.poi.name);  //关键字查询查询
              })//注册监听，当选中某条记录时会触发
            })
            this.mapInterface.plugin(["AMap.MouseTool"], () => {
              //鼠标工具插件
              this.mapMouse = new this.amap.MouseTool(this.mapInterface);
              this.mapMouse.on("draw", e => {
                if (e.obj.CLASS_NAME == "AMap.Circle") {
                  this.coordinate = [[e.obj.De.center.lng, e.obj.De.center.lat]];
                  this.infoForm.radius = e.obj.De.radius;
                  this.infoForm.coordinate = [
                    {
                      longitude: e.obj.De.center.lng,
                      latitude: e.obj.De.center.lat
                    }
                  ];
                  this.mapMouse.close(true);
                  // this.mapInterface.clearMap();
                  this.circleAmap();
                } else if (e.obj.CLASS_NAME == "AMap.Polygon") {
                  this.coordinate = e.obj.De.path;
                  e.obj.De.path.forEach(v => {
                    this.infoForm.coordinate.push({
                      longitude: v.lng,
                      latitude: v.lat
                    });
                  });
                  this.mapMouse.close(true);
                  // this.mapInterface.clearMap();
                  this.polygonAmap();
                }
              });
            });
            if (this.$route.query.id > 0) {
              this.loadFenceInfo();
            }
          })
          .catch(e => {
            console.log(e);
          });
    },
    circleAmap() {
      if (!tool.isEmpty(this.coordinate)) {
        const circle = new this.amap.Circle({
          center: this.coordinate[0],
          borderWeight: 1, // 轮廓线宽度
          strokeColor: this.color[this.infoForm.level],
          strokeOpacity: 1, // 轮廓线透明度
          fillOpacity: 0.4, // 填充透明度
          fillColor: this.color[this.infoForm.level],
          offset: 1,
          radius: this.infoForm.radius // 半径
        });
        circle.setMap(this.mapInterface);
        // 缩放地图到合适的视野级别
        this.mapInterface.setFitView();

        this.mapInterface.plugin(["AMap.CircleEditor"], () => {
          //鼠标工具插件
          var circleEditor = new this.amap.CircleEditor(
              this.mapInterface,
              circle
          );
          circleEditor.open(); // 开始编辑操作
          circleEditor.on("move", event => {
            this.infoForm.coordinate = [
              {
                longitude: event.lnglat.lng,
                latitude: event.lnglat.lat
              }
            ];
          });
          circleEditor.on("adjust", event => {
            this.infoForm.radius = event.radius;
          });
        });
      }
    },
    polygonAmap() {
      if (!tool.isEmpty(this.coordinate)) {
        const polygon = new AMap.Polygon({
          path: this.coordinate,
          strokeColor: this.color[this.infoForm.level],
          strokeWeight: 1, // 轮廓线宽度
          strokeOpacity: 1, // 轮廓线透明度
          fillOpacity: 0.4, // 填充透明度
          fillColor: this.color[this.infoForm.level], // 填充颜色
          zIndex: 50
        });
        this.mapInterface.add(polygon);
        // 缩放地图到合适的视野级别
        this.mapInterface.setFitView();
        // this.mapInterface.plugin(['AMap.PolyEditor'], () => {        //鼠标工具插件
        //   var polyEditor = new this.amap.PolyEditor(this.mapInterface, polygon);
        //   polyEditor.open();
        //   polyEditor.on('addnode', (event) => {
        //     this.coordinate = event.target.Ce.path;
        //   });
        //
        //   polyEditor.on('adjust', (event) => {
        //     this.coordinate = event.target.Ce.path;
        //   });
        //
        //   polyEditor.on('removenode', (event) => {
        //     this.coordinate = event.target.Ce.path;
        //   })
        // })
      }
    },
    clearFence() {
      this.$confirm(this.$t('device.delete_note'), this.$t('device.tip'), {
        confirmButtonText: this.$t('device.confirm'),
        cancelButtonText: this.$t('device.cancel'),
        type: 'warning'
      }).then(() => {
        this.clear()
      }).catch(() => {
      })
    },
    clear() {
      if (this.mapInterface) {
        this.mapInterface.clearMap();
      }
      // 将以上覆盖物添加到地图上
      // 单独将点标记添加到地图上
      if (this.longitude != '' && this.latitude != '') {
        let marker = new AMap.Marker({
          icon: this.is_online !== 1 ? this.$store.state.outline : this.$store.state.online,
          position: [this.longitude, this.latitude],
          title: this.location
        });
        let trafficLayer = new AMap.TileLayer.Traffic({
          zIndex: 2
        });
        this.mapInterface.add(marker);
        this.mapInterface.add(trafficLayer);
      }
      this.coordinate = [];
      setTimeout(() => {
        if (this.infoForm.shape == 1) {
          this.mapMouse.circle({
            borderWeight: 1, // 轮廓线宽度
            strokeColor: this.color[this.infoForm.level],
            strokeOpacity: 1, // 轮廓线透明度
            fillOpacity: 0.4, // 填充透明度
            fillColor: this.color[this.infoForm.level],
            radius: this.infoForm.radius // 半径
          });
        } else {
          this.mapMouse.polygon({
            fillColor: this.color[this.infoForm.level],
            strokeColor: this.color[this.infoForm.level]
          });
        }
      });
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.card-header {
  display: flex;
  align-items: center;
}

.el-table {
  margin-top: 20px;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}

.el-form {
  width: 100%;
}

.el-dialog::v-deep .el-dialog__body {
  padding-bottom: 100px !important;
}

.addinfo::v-deep .el-form-item__content {
  display: flex !important;
}

.addinfo::v-deep .el-form-item__label {
  float: left;
}

.el-button {
  margin-left: 10px;
}
</style>
